import { useState } from "react";

import { FreeTimeContainer } from "@sellernote/_shared/src/api-interfaces/shipda-api/freeTime";

export default function useFilter({
  containers,
}: {
  containers?: Array<FreeTimeContainer>;
}) {
  const containerNoFilterOptions =
    containers?.map(({ containerNo }) => ({
      label: containerNo,
      value: containerNo,
    })) ?? [];

  const [filteredContainerNoList, setFilteredContainerNoList] = useState<
    string[]
  >([]);

  const handleFilterSelect = (value: string[]) => {
    setFilteredContainerNoList(value);
  };

  return {
    containerNoFilterOptions,
    filteredContainerNoList,
    handleFilterSelect,
  };
}
